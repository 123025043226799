import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Homepage from '../HomePage';

import ServicePage from '../ServicePage';
import SeviceSinglePage from '../SeviceSinglePage';
import CauseSinglePage from '../CauseSinglePage';
import TeamSinglePage from '../TeamSinglePage';
import EventPage from '../EventPage';
import EventPage2 from '../EventPage2';
import EventSinglePage from '../EventSinglePage';
import ProjectPage from '../ProjectPage';
import ProjectSinglePage from '../ProjectSinglePage';
import CausePage from '../CausePage';
import TestimonialPage from '../TestimonialPage';
import BlogPage from '../BlogPage';
import BlogPageLeft from '../BlogPageLeft';
import BlogPageFullwidth from '../BlogPageFullwidth';
import BlogDetails from '../BlogDetails';
import BlogDetailsFull from '../BlogDetailsFull';
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide';
import ContactPage from '../ContactPage';
import ErrorPage from '../ErrorPage';
import DonatePage from '../DonatePage';
import VolunteerPage from '../VolunteerPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';
import EventSinglePage2 from '../EventSinglePage2';
import ClientsPage from '../ClientsPage';
import MissionPage from '../MissionPage';
import AboutPage from '../AboutPage';
import GalleryPage from '../GalleryPage';
import { GET_MENUS_ALL_NESTED } from '../../constant/constants';
import Navbar2 from '../../components/Navbar2';
import PerformancePage from '../PerformancePage';
import CompanyPage from '../CompanyPage';
import ProfilePage from '../ProfilePage';
import DocumentPage from '../DocumentPage';
import NoticePage from '../NoticePage';
import PdfShowPage from '../PdfShowPage';
import EmployeesPage from '../EmployeesPage';
import HowWeWorkPage from '../HowWeWorkPage';

const AllRoute = ({ logo }) => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === 'Home' ? setHomeId(e.id) : null));
      })
      .catch(() => {});
  }, []);

  //console.log("nnnnnnn", homeId);
  return (
    <div className='App'>
      <Router>
        <Navbar2 Logo={logo} id={homeId} hclass={'wpo-header-style-4'} />

        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/home/:id' component={Homepage} />
          {/* <Route path="/home2" component={Homepage2} /> */}
          {/* <Route path="/home3" component={Homepage3} />
          <Route path="/home4" component={Homepage4} />
          <Route path="/home5" component={Homepage5} />
          <Route path="/home6" component={Homepage6} />
          <Route path="/home7" component={Homepage7} /> */}
          <Route path='/about/:id' component={AboutPage} />
          <Route path='/service/:id' component={ServicePage} />
          <Route path='/clients/:id' component={ClientsPage} />
          <Route path='/notice/' component={NoticePage} />
          <Route path='/our team/' component={EmployeesPage} />
          <Route path='/pdfShow/notice/:id' component={PdfShowPage} />
          <Route path='/service-single/:id' component={SeviceSinglePage} />
          <Route path='/message/:id' component={EventSinglePage2} />
          <Route path='/mission/:id' component={MissionPage} />
          <Route path='/how we work/:id' component={HowWeWorkPage} />
          <Route path='/performance/:id' component={PerformancePage} />
          <Route path='/profile/:id' component={ProfilePage} />
          <Route path='/gallery/:id' component={GalleryPage} />
          <Route path='/document/:id' component={DocumentPage} />
          <Route path='/company/:id/:companyName?' component={CompanyPage} />
          <Route path='/cause' component={CausePage} />
          <Route path='/cause-single/:id' component={CauseSinglePage} />
          <Route path='/team-single/:id' component={TeamSinglePage} />
          <Route path='/event' component={EventPage} />
          <Route path='/event-s2' component={EventPage2} />
          <Route path='/event-single/:id' component={EventSinglePage} />
          <Route path='/project' component={ProjectPage} />
          <Route path='/project-single/:id' component={ProjectSinglePage} />
          <Route path='/testimonial' component={TestimonialPage} />
          <Route path='/blog-single/:id' component={BlogDetails} />
          <Route
            path='/blog-single-left-sidebar/:id'
            component={BlogDetailsLeftSiide}
          />
          <Route
            path='/blog-single-fullwidth/:id'
            component={BlogDetailsFull}
          />
          <Route path='/blog' component={BlogPage} />
          <Route path='/blog-left-sidebar' component={BlogPageLeft} />
          <Route path='/blog-fullwidth' component={BlogPageFullwidth} />
          <Route path='/donate' component={DonatePage} />
          <Route path='/volunteer' component={VolunteerPage} />
          <Route path='/404' component={ErrorPage} />
          <Route path='/contact' component={ContactPage} />

          {/* <Route path='/attorneys' component={AttorneysPage} />
            <Route path='/pricing' component={PricingPage} />
            <Route path='/practice' component={PracticePage}/>
            <Route path='/practice-s2' component={PracticePageS2}/>
            <Route path='/case-single/:id' component={caseSinglePage}/>
            <Route path='/shop' component={ShopPage}/>
            <Route path='/shop-single/:id' component={ShopSinglePage}/>
            <Route path='/cart' component={CartPage}/>
            <Route path='/checkout' component={CheckoutPage}/>
            <Route path='/faq' component={FaqPage}/> */}

          <Route path='/login' component={LoginPage} />
          <Route path='/register' component={SignUpPage} />
          <Route path='/forgot-password' component={ForgotPassword} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
