import React, { useEffect, useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from '../../constant/constants';
import { Interweave } from 'interweave';

function items(obj) {
  let content = [];

  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const EventTabs = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [contentsImage, setContentsImage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id } = useParams();
  const [contents, setContents] = useState('');

  console.log('menuID', id);

  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
        console.log('imagesMessage', data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
        console.log('ContactContent', data);
      })
      .catch(() => {});
  }, [id, props.id]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const imageHeight = windowWidth < 600 ? '250px' : '650px';

  const newContents = items(contents);
  console.log('content Contact', newContents);

  return (
    <div className='wpo-event-details-wrap'>
      <div className='wpo-event-details-tab'>
        {newContents.map((service, index) =>
          Object.entries(service).map(([key, value]) =>
            key === 'Proprietor' ? <h1 className='text-center'>{key}</h1> : ' '
          )
        )}
      </div>

      <div className='wpo-event-details-content'>
        <Row>
          <Col sm='12'>
            <div id='' className=''>
              {Object.entries(contentsImage).map(([key, value]) =>
                key === 'Proprietor' ? (
                  <div key={key}>
                    <img
                      style={{ width: '100%', height: imageHeight }}
                      src={`${BASE_URL}/media/${value}`}
                      alt=''
                    />
                  </div>
                ) : (
                  ' '
                )
              )}
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === 'Proprietor' ? (
                    <React.Fragment key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </React.Fragment>
                  ) : (
                    ' '
                  )
                )
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EventTabs;
