import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import HowWeWork from '../../components/HowWeWork';

const HowWeWorkPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={'How We Work'} pagesub={'How We Work'} />
      <HowWeWork />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HowWeWorkPage;
